@charset "utf-8";

div{
	box-sizing:content-box;
}

.yzm {
    width: 100%;
    height: 100%;
    min-height: 910px;
    display: block;
    font-family: 'Microsoft YaHei', 'Times New Roman', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: url(/static/images/login/doctor-loginBG.jpg) no-repeat;
    background-size: 100% 100%;
    position: absolute;
}

.yzm-header {
    width: 339px;
    height: 122px;
    display: block;
    background: url(/static/images/login/nav-logo1@2x.png) no-repeat center;
    background-size: 100%;
    margin: 0 auto;
    padding-top: 110px;
}

.yzm-content {
    width: 970px;
    height: 520px;
    background-color: #ffffff;
    box-shadow: 0px 5px 30px 0px rgba(10, 20, 50, 0.22);
    border-radius: 4px;
    margin: 0 auto;
}

.login-content {
    display: inline-block;
    margin-left: 70px;
    margin-top: 49px;
}

.login-logo {
    width: 197px;
    height: 62px;
    display: block;
    background: url(/static/images/login/doctorLogin.png) no-repeat center;
    background-size: 100%;
}

.login-char {
	width: 421px;
	height: 44px;
	margin-top: 38px;
}

.login-char label {
    line-height: 16px;
    color: #7a7a7a;
    font-size: 14px;
}

.login-input {
    margin-top: 64px;
}

.login-input-with-code{
	margin-top: 41px;
}

.login-psw {
    width: 300px;
    height: 40px;
    margin-top: 15px;
}

.login-name {
    width: 300px;
    height: 40px;
}

.login-img {
    float: right;
    width: 316px;
    height: 517px;
    background: url(/static/images/login/login-dcarer.png) no-repeat center;
    background-size: 100%;
    margin-top: 3px;
}

.btn {
    text-align: center;
    width: 300px;
    height: 40px;
    line-height: 40px;
    background-color: #4b467e;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 25px;
}

.btn:hover {
    background-color: #544f85;
}

.login-error {
    width: 300px;
    text-align: center;
    margin-top: 10px;
}

.login-error span {
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 13px;
    border-radius: 11px;
    color: #ff8125;
    text-align: center;
    cursor: default;
    border: 1px solid #ff8125;
    display: inline-block;
    box-sizing: border-box;
}

.login-error label {
    line-height: 13px;
    color: #ff8a35;
    padding-left: 4px;
}

.yzm-footer {
    width: 100%;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    bottom: 0;
}

.yzm-footer label {
    line-height: 50px;
    color: #ffffff;
}
.yzm-footer label a{
    color: #595392;
    padding-left: 8px;
    text-decoration: none;
}

.vcode {
	width:108px;
	height: 38px;
	border: solid 1px #dcdcdc;
	border-radius: 4px;
	float: right;
}

.login-vcode{
	display: none;
	cursor:pointer;
}

.login-forget{
    position: relative;
    width: 300px;
    height: 16px;
    margin-top: 20px;
}
.login-forget a{
    position:absolute;
	font-size: 14px;
	line-height: 16px;
	color: #7a7a7a;  	
	text-decoration: none; 	
}

.login-forget a:hover{
	color: #4b467e;  	
	text-decoration: underline;	
}

.login-forget .goRegister{
    left: 0;
    color: #4b467e;
}

.goForget{
    right: 0;
}